import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2016-11-30",
  "title": "5 Signs you Need a New Project",
  "description": "How to know when your current project is no longer a good fit for you",
  "cover": "/img/cover/beach.jpg",
  "pic": "/img/beach.jpg",
  "color": "#81857f"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Do you simply need to work harder on your attitude, or is it time for a change? Answering this question is hard. But when you find yourself sapped of motivation to code on your project at work - daydreaming about your side projects or your next vacation - you `}<em parentName="p">{`have`}</em>{` to figure it out ASAP.`}</p>
    <p>{` Being in a funk sucks. I went through the worst one of my career recently. I tried every positivity trick in the book, staying on a project that was no longer good for me for far too long. Special thanks to some close friends who helped me finally see it. I'm hoping I can help others see the red flags that I only recognized in hindsight, and help get you back to productive and happy coding.`}</p>
    <p>{` Here are 5 signs you need a new project:`}</p>
    <h2>{`1. The Code has Become the Product`}</h2>
    <p>{`Many devs are naturally inclined to the architecture/tech side of things. I did a poll on twitter once asking if people cared more about what they were building or the stack they were using to build it. The results were suprisingly split down the middle. So for many of us this is a subtle, easy red flag to miss.`}</p>
    <p>{`It happens `}<strong parentName="p">{`when you start to think of the codebase - rather than the end result that real people will use - as the product`}</strong>{`. You may add exciting tech to the project that if you were being absolutely honest is out of complete boredom from the "CRUD app" you're building. You value the developer experience over the user experience. You rarely even think of or interact with the users. `}</p>
    <h2>{`2. You Welcome Distractions`}</h2>
    <p>{`You look forward to things in the day that you `}<em parentName="p">{`used`}</em>{` to see as annoying interruptions to your precious coding time: meetings, emails, company chat, etc. Anything - no matter how mundane - that distracts you from that `}<strong parentName="p">{`meh`}</strong>{` feeling.`}</p>
    <h2>{`3. You Aren't Learning`}</h2>
    <p>{`Think of all the new things you've learned in the last while. The new languages, libraries, frameworks, databases, & tools are super important. But even more valuable are the experiences you gain once you've got those down. The kinds of lessons you only learn when you're applying your passion and talents, solving problems, and building something you care about and are proud of.`}</p>
    <p>{`If pretty much all of your learning has come from your open source/side projects, that's a huge red flag.`}</p>
    <h2>{`4. You're Negative`}</h2>
    <p>{`When you're feeling miserably unproductive, it's hard to be positive about anything. Your minor gripes with the managers, company, and product turn into a constant, contagious stream of negativity that your friends don't want to be around. It's not a fun or healthy state. `}</p>
    <h2>{`5. You Haven't Shipped`}</h2>
    <p>{`Shipping is your lifeblood. It's when all your hard work pays off. Because of you another human's life is a little bit better. This awesome feeling that you're making a difference in the world by playing with your computer is probably the drug that got you into coding in the first place. Amazing that people pay us to do this right?! So yeah, if it's been literally years since your project has shipped, get off it. Find something that ships, often. With people you really like being around. Preferably something that has the potential to delight the people that will use it.`}</p>
    <p>{` Also don't stay too long on a project after you've shipped the major version you helped build. It will either go into maintenance/stability/scalability mode or be ready for a v2. Usually best to have either case be a good new project for someone else. `}</p>
    <h2>{`Are you on the right project?`}</h2>
    <p>{`For developers `}<strong parentName="p">{`happiness and productivity are tightly coupled`}</strong>{`. If you can achieve productivity, you'll likely find happiness at work. If you can find happiness, your productivity will likewise increase. `}<strong parentName="p">{`Being productive and happy is `}<em parentName="strong">{`so`}</em>{` much easier on a project that's a good fit`}</strong>{`. Once you find that good fit you'll care again about what you're building and who you're building it for. You'll have that healthy distaste for meetings :) You'll learn and grow a ton, hopefully make some lifelong friends, all while feeling positive and making an impact. Are you on the right project?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      